import React from 'react';
import * as S from './our-project.style';
import Link from 'next/link';
import { Tag } from '@components/ui/tag/tag';
import { ExternalRelinkItem } from '@components/main/blocks/our-projects/our-proejcts';
import { useStore } from '@stores/root';
import { observer } from 'mobx-react-lite';

type Props = ExternalRelinkItem;

export const OurProject = observer((props: Props) => {
  const { geo, project } = useStore();
  const tags = props.tags ?? [];

  return (
    <S.StyledOurProject>
      <S.OurProjectLink>
        <Link
          legacyBehavior
          prefetch={false}
          href={
            `https://liski.${props.domain}`
          }
        >
          {props.domain}
        </Link>
      </S.OurProjectLink>

      <S.OurProjectTags>
        {tags.map((t) => (
          <Tag
            key={t.target_url}
            href={
              `https://liski.${props.domain}${t.target_url}`
            }
          >
            {t.text}
          </Tag>
        ))}
      </S.OurProjectTags>
    </S.StyledOurProject>
  );
});
